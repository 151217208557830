import React, { useContext } from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import ManagerOverview from "../../Components/Manager/ManagerOverview";
import {
  GET_TEAMS_BY_MANAGER_ID,
  TEAMS_WITH_PERMISSION,
} from "../../GraphQL/Teams.js";
import { GET_MANAGER, GET_MANAGERS } from "../../GraphQL/Managers.js";
import { LoginContext } from "../../Components/Login/LoginContext";
import TeamTable from "../../Components/Team/TeamTable.js";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import NewTeamButton from "../../Components/Team/NewTeamButton.js";

const ManagerPage = () => {
  const { managerId } = useParams();

  const managers = useQuery(GET_MANAGERS, {
    pollInterval: 5000,
  });

  const teamsManaged = useQuery(GET_TEAMS_BY_MANAGER_ID, {
    variables: {
      id: managerId,
    },
    pollInterval: 5000,
  });

  const manager = useQuery(GET_MANAGER, {
    variables: {
      id: managerId,
    },
  });
  const [loggedIn] = useContext(LoginContext);

  const teamsWithPermission = useQuery(TEAMS_WITH_PERMISSION, {
    pollInterval: 5000,
  });

  if (
    teamsManaged.loading ||
    manager.loading ||
    teamsWithPermission.loading ||
    managers.loading
  )
    return <LoadingDialog />;

  return (
    <div className="container">
      <h3>Manager</h3>
      <ManagerOverview manager={manager.data.manager} />
      <h3>Teams</h3>
      {loggedIn && <NewTeamButton managerId={managerId}></NewTeamButton>}
      <TeamTable
        managers={managers.data.managers}
        teams={teamsManaged.data.teamsByManagerId}
        teamsWithPermission={teamsWithPermission.data.teamsWithPermission}
      ></TeamTable>
    </div>
  );
};

export default ManagerPage;
