import React from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { GET_USER } from "../../GraphQL/Users.js";
import { GET_PLAYER_BY_USER_ID } from "../../GraphQL/Players.js";
import { GET_MANAGER_BY_USER_ID } from "../../GraphQL/Managers.js";
import { GET_COMPETITIONS_BY_USER_ID } from "../../GraphQL/Competitions";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import PlayerButton from "../../Components/Player/PlayerButton.js";
import ManagerButton from "../../Components/Manager/ManagerButton.js";
import CompetitionsTable from "../../Components/Competition/CompetitionsTable.js";
import { Grid } from "@mui/material";
import "../../Styles/style.css";

const UserPage = () => {
  const { userId } = useParams();

  const user = useQuery(GET_USER, {
    variables: {
      id: userId,
    },
  });

  const player = useQuery(GET_PLAYER_BY_USER_ID, {
    variables: {
      id: userId,
    },
  });

  const manager = useQuery(GET_MANAGER_BY_USER_ID, {
    variables: {
      userId: userId,
    },
  });

  const competitions = useQuery(GET_COMPETITIONS_BY_USER_ID, {
    variables: {
      id: userId,
    },
  });

  if (user.loading || player.loading || manager.loading || competitions.loading)
    return <LoadingDialog />;

  return (
    <div className="container">
      <h3>User</h3>
      <Grid container spacing={2}>
        <Grid item xs={4}></Grid>
        <Grid item xs={2}>
          {player.data.playerByUserId && (
            <PlayerButton
              playerId={player.data.playerByUserId.id}
            ></PlayerButton>
          )}
        </Grid>
        <Grid item xs={2}>
          {manager.data.managerByUserId && (
            <ManagerButton
              managerId={manager.data.managerByUserId.id}
            ></ManagerButton>
          )}
        </Grid>
        <Grid item xs={4}></Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <ul>
            <li>Discord ID: {user.data.user.discordId}</li>
            <li>Discriminator: {user.data.user.discriminator}</li>
            <li>Username: {user.data.user.username}</li>
            <li>Icon: {user.data.user.icon}</li>
            <li>Origin ID: {user.data.user.originId}</li>
          </ul>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>

      <h3>Owned Competitions</h3>
      <CompetitionsTable
        competitionsWithPermission={[]}
        competitions={competitions.data.competitionsByUserId}
      ></CompetitionsTable>
    </div>
  );
};

export default UserPage;
