import { gql } from "@apollo/client";

export const GET_MATCHES_BY_SEASON_ID = gql`
  query matchesBySeasonId($id: Long) {
    matchesBySeasonId(id: $id) {
      id
      homeTeam {
        name
      }
      awayTeam {
        name
      }
      startDate
      hasBeenPlayed
    }
  }
`;

export const GET_MATCH_BY_ID = gql`
  query match($id: Long) {
    match(id: $id) {
      id
      homeTeam {
        id
        name
      }
      awayTeam {
        id
        name
      }
      startDate
      hasBeenPlayed
      season {
        id
        id
        name
        startDate
        endDate
        competition {
          name
        }
      }
    }
  }
`;

export const ADD_MATCH = gql`
  mutation createMatch(
    $seasonId: Long!
    $awayTeamId: Long!
    $homeTeamId: Long!
    $startDate: Date!
    $hasBeenPlayed: Boolean!
  ) {
    createMatch(
      seasonId: $seasonId
      awayTeamId: $awayTeamId
      homeTeamId: $homeTeamId
      startDate: $startDate
      hasBeenPlayed: $hasBeenPlayed
    ) {
      id
      homeTeam {
        id
        name
      }
      awayTeam {
        id
        name
      }
      startDate
      hasBeenPlayed
      season {
        id
        name
        startDate
        endDate
        competition {
          name
        }
      }
    }
  }
`;

export const UPDATE_MATCH = gql`
  mutation updateMatch(
    $id: Long!
    $startDate: Date!
    $hasBeenPlayed: Boolean!
  ) {
    updateMatch(id: $id, startDate: $startDate, hasBeenPlayed: $hasBeenPlayed) {
      id
      homeTeam {
        id
        name
      }
      awayTeam {
        id
        name
      }
      startDate
      hasBeenPlayed
      season {
        id
        name
        startDate
        endDate
        competition {
          name
        }
      }
    }
  }
`;

export const DELETE_MATCH = gql`
  mutation deleteMatch($id: Long!) {
    deleteMatch(id: $id) {
      id
      homeTeam {
        id
        name
      }
      awayTeam {
        id
        name
      }
      startDate
      hasBeenPlayed
      season {
        id
        name
        startDate
        endDate
        competition {
          name
        }
      }
    }
  }
`;

export const MATCHES_WITH_PERMISSION = gql`
  query matchesWithPermission {
    matchesWithPermission {
      id
    }
  }
`;
