import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const LeagueTable = (props) => {
  const rows = [];

  // Teams
  const teams = props.appearances
    .map((appearance) => appearance.team)
    .filter((value, index, self) => self.indexOf(value) === index);

  // Matches
  const matches = props.appearances
    .map((appearance) => appearance.match)
    .filter((value, index, self) => self.indexOf(value) === index);

  teams.forEach((team, index) => {
    const row = {
      id: 0,
      team: "",
      wins: 0,
      draws: 0,
      losses: 0,
      gamesPlayed: 0,
      goalsFor: 0,
      goalsAgainst: 0,
      goalDifference: 0,
      points: 0,
    };

    const teamMatchIds = matches
      .filter(
        (value, index, self) =>
          value.homeTeam.id === team.id || value.awayTeam.id === team.id
      )
      .map((match) => match.id);

    const teamAppearancesInMatches = props.appearances.filter(
      (value, index, self) =>
        teamMatchIds.indexOf(value.match.id) >= 0 && value.team.id === team.id
    );

    const oppositionAppearancesInMatches = props.appearances.filter(
      (value, index, self) =>
        teamMatchIds.indexOf(value.match.id) >= 0 && value.team.id !== team.id
    );

    const goalsFor = teamAppearancesInMatches
      .map((appearance) => appearance.goals)
      .reduce((g1, g2) => g1 + g2, 0);

    const goalsAgainst = oppositionAppearancesInMatches
      .map((appearance) => appearance.goals)
      .reduce((g1, g2) => g1 + g2, 0);

    const appearancesByMatch = new Map();
    props.appearances.forEach((appearance) => {
      if (appearancesByMatch.has(appearance.match.id) === false) {
        appearancesByMatch.set(appearance.match.id, []);
      }

      const appearances = appearancesByMatch.get(appearance.match.id);
      appearances.push(appearance);
    });

    appearancesByMatch.forEach((appearances) => {
      const currentTeamAppearancesInMatch = appearances.filter(
        (value, index, self) => value.team.id === team.id
      );

      const oppositionTeamAppearancesInMatch = appearances.filter(
        (value, index, self) => value.team.id !== team.id
      );

      const goalsFor = currentTeamAppearancesInMatch
        .map((appearance) => appearance.goals)
        .reduce((g1, g2) => g1 + g2, 0);

      const goalsAgainst = oppositionTeamAppearancesInMatch
        .map((appearance) => appearance.goals)
        .reduce((g1, g2) => g1 + g2, 0);

      if (goalsFor > goalsAgainst) {
        row.wins = row.wins + 1;
      } else if (goalsFor < goalsAgainst) {
        row.losses = row.losses + 1;
      } else {
        row.draws = row.draws + 1;
      }
    });

    row.id = index;
    row.team = team.name;
    row.gamesPlayed = row.wins + row.draws + row.losses;
    row.goalsFor = goalsFor;
    row.goalsAgainst = goalsAgainst;
    row.goalDifference = row.goalsFor - row.goalsAgainst;
    row.points = row.wins * 3 + row.draws;

    rows.push(row);
  });

  return (
    <div>
      <TableContainer data-testid="leagueTable" component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Team</TableCell>
              <TableCell>GP</TableCell>
              <TableCell>W</TableCell>
              <TableCell>D</TableCell>
              <TableCell>L</TableCell>
              <TableCell>GF</TableCell>
              <TableCell>GA</TableCell>
              <TableCell>GD</TableCell>
              <TableCell>P</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                hover
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row.team}</TableCell>
                <TableCell>{row.gamesPlayed}</TableCell>
                <TableCell>{row.wins}</TableCell>
                <TableCell>{row.draws}</TableCell>
                <TableCell>{row.losses}</TableCell>
                <TableCell>{row.goalsFor}</TableCell>
                <TableCell>{row.goalsAgainst}</TableCell>
                <TableCell>{row.goalDifference}</TableCell>
                <TableCell>{row.points}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default LeagueTable;
