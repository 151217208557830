import React, { useState } from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteAppearanceDialog from "./DeleteAppearanceDialog";

const DeleteAppearanceButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Button
        data-testid="deleteAppearanceButton"
        sx={{ marginLeft: 1 }}
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        <DeleteIcon />
      </Button>

      <DeleteAppearanceDialog
        open={open}
        setOpen={setOpen}
        appearance={props.appearance}
      ></DeleteAppearanceDialog>
    </div>
  );
};

export default DeleteAppearanceButton;
