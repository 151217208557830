import { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";
import { ADD_APPEARANCE } from "../../GraphQL/Appearances";
import PlayerSelect from "../Player/PlayerSelect";

const NewAppearanceDialog = (props) => {
  const [appearance, setAppearance] = useState("");
  const [player, setPlayer] = useState(null);

  const [addAppearance, { loading, error }] = useMutation(ADD_APPEARANCE, {
    variables: {
      matchId: props.matchId,
      teamId: props.teamId,
      playerId: player,
      goals: parseInt(appearance.goals),
      assists: parseInt(appearance.assists),
      passes: parseInt(appearance.passes),
      interceptions: parseInt(appearance.interceptions),
      tackles: parseInt(appearance.tackles),
      yellowCards: parseInt(appearance.yellowCards),
      redCards: parseInt(appearance.redCards),
    },
  });

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>Appearance</DialogTitle>
        <DialogContent>
          <PlayerSelect
            player={player}
            setPlayer={setPlayer}
            players={props.players}
          />
          <TextField
            data-testid="goals"
            autoFocus
            margin="dense"
            id="goals"
            label="Goals"
            type="number"
            fullWidth
            variant="standard"
            onChange={(e) => {
              var value = parseInt(e.target.value);

              if (value > 50) value = 50;
              if (value < 0) value = 0;
              e.target.value = value;
              setAppearance({ ...appearance, goals: value });
            }}
            InputProps={{ inputProps: { min: 0, max: 50 } }}
          />
          <TextField
            data-testid="assists"
            autoFocus
            margin="dense"
            id="assists"
            label="Assists"
            type="number"
            fullWidth
            variant="standard"
            onChange={(e) => {
              var value = parseInt(e.target.value);

              if (value > 50) value = 50;
              if (value < 0) value = 0;
              e.target.value = value;
              setAppearance({ ...appearance, assists: value });
            }}
            InputProps={{ inputProps: { min: 0, max: 50 } }}
          />
          <TextField
            data-testid="passes"
            autoFocus
            margin="dense"
            id="passes"
            label="Passes"
            type="number"
            fullWidth
            variant="standard"
            onChange={(e) => {
              var value = parseInt(e.target.value);

              if (value > 50) value = 50;
              if (value < 0) value = 0;
              e.target.value = value;
              setAppearance({ ...appearance, passes: value });
            }}
            InputProps={{ inputProps: { min: 0, max: 50 } }}
          />
          <TextField
            data-testid="interceptions"
            autoFocus
            margin="dense"
            id="interceptions"
            label="Interceptions"
            type="number"
            fullWidth
            variant="standard"
            onChange={(e) => {
              var value = parseInt(e.target.value);

              if (value > 50) value = 50;
              if (value < 0) value = 0;
              e.target.value = value;
              setAppearance({ ...appearance, interceptions: value });
            }}
            InputProps={{ inputProps: { min: 0, max: 50 } }}
          />
          <TextField
            data-testid="tackles"
            autoFocus
            margin="dense"
            id="tackles"
            label="Tackles"
            type="number"
            fullWidth
            variant="standard"
            onChange={(e) => {
              var value = parseInt(e.target.value);

              if (value > 50) value = 50;
              if (value < 0) value = 0;
              e.target.value = value;
              setAppearance({ ...appearance, tackles: value });
            }}
            InputProps={{ inputProps: { min: 0, max: 50 } }}
          />
          <TextField
            data-testid="yellowCards"
            autoFocus
            margin="dense"
            id="yellowCards"
            label="Yellow Cards"
            type="number"
            fullWidth
            variant="standard"
            onChange={(e) => {
              var value = parseInt(e.target.value);

              if (value > 2) value = 2;
              if (value < 0) value = 0;
              e.target.value = value;
              setAppearance({ ...appearance, yellowCards: value });
            }}
            InputProps={{ inputProps: { min: 0, max: 2 } }}
          />
          <TextField
            data-testid="redCards"
            autoFocus
            margin="dense"
            id="redCards"
            label="Red Cards"
            type="number"
            fullWidth
            variant="standard"
            onChange={(e) => {
              var value = parseInt(e.target.value);

              if (value > 1) value = 1;
              if (value < 0) value = 0;
              e.target.value = value;
              setAppearance({ ...appearance, redCards: value });
            }}
            InputProps={{ inputProps: { min: 0, max: 1 } }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="saveNewAppearanceDialogButton"
            onClick={() => {
              addAppearance();
              props.setOpen(false);
            }}
          >
            Create
          </Button>
          <Button
            data-testid="closeNewAppearanceDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewAppearanceDialog;
