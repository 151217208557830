import * as React from "react";
import { Divider, Stack, Chip } from "@mui/material";
import "../Styles/style.css";

const News = () => {
  return (
    <div>
      <Stack spacing={10}>
        <Divider>
          <Chip label="dd/mm/yyyy - News Title" />
        </Divider>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          urna orci, tempor sed lobortis nec, vulputate id leo. Aliquam placerat
          augue eu magna viverra, sed ultricies est eleifend. Sed cursus, sem
          nec lobortis consectetur, elit sem ullamcorper felis, et efficitur
          ipsum risus at ex. Mauris quis quam ut tortor consequat efficitur eget
          eget nunc. Cras eros felis, blandit eu tincidunt suscipit, imperdiet
          quis mi. Cras non nibh molestie, eleifend sapien at, auctor purus.
          Proin non ante a orci tincidunt ullamcorper eu eu est. Quisque finibus
          auctor nisl vel fringilla. Proin vel gravida leo, a viverra eros.
          Etiam quis quam vitae justo elementum accumsan nec id ante.
        </p>
        <p>
          Nullam in purus sit amet felis egestas molestie. Praesent maximus
          massa eget turpis scelerisque aliquam. Pellentesque eget erat urna.
          Mauris et vehicula justo, et convallis arcu. Praesent facilisis
          imperdiet dapibus. Nam tincidunt lacus ac arcu pharetra posuere.
          Integer sodales sapien in justo maximus efficitur. Phasellus rutrum id
          mi ac fringilla. Etiam urna augue, aliquet vel tempus non, consequat
          eget orci.
        </p>
        <Divider>
          <Chip label="dd/mm/yyyy - Introduction to Pro Clubs Football Association" />
        </Divider>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          urna orci, tempor sed lobortis nec, vulputate id leo. Aliquam placerat
          augue eu magna viverra, sed ultricies est eleifend. Sed cursus, sem
          nec lobortis consectetur, elit sem ullamcorper felis, et efficitur
          ipsum risus at ex. Mauris quis quam ut tortor consequat efficitur eget
          eget nunc. Cras eros felis, blandit eu tincidunt suscipit, imperdiet
          quis mi. Cras non nibh molestie, eleifend sapien at, auctor purus.
          Proin non ante a orci tincidunt ullamcorper eu eu est. Quisque finibus
          auctor nisl vel fringilla. Proin vel gravida leo, a viverra eros.
          Etiam quis quam vitae justo elementum accumsan nec id ante.
        </p>
        <p>
          Nullam in purus sit amet felis egestas molestie. Praesent maximus
          massa eget turpis scelerisque aliquam. Pellentesque eget erat urna.
          Mauris et vehicula justo, et convallis arcu. Praesent facilisis
          imperdiet dapibus. Nam tincidunt lacus ac arcu pharetra posuere.
          Integer sodales sapien in justo maximus efficitur. Phasellus rutrum id
          mi ac fringilla. Etiam urna augue, aliquet vel tempus non, consequat
          eget orci.
        </p>
      </Stack>
    </div>
  );
};

export default News;
