import React from "react";
import { Select, MenuItem, InputLabel } from "@mui/material";

const PlayerSelect = (props) => {
  const handlePlayerChange = (event) => {
    props.setPlayer(event.target.value);
  };

  const rows = props.players.map(({ id, name }) => ({
    id: id,
    name: name,
  }));
  console.log(rows);
  return (
    <div>
      <InputLabel id="player-select-label">Player</InputLabel>
      <Select
        data-testid="player-select"
        labelId="player-select-label"
        onChange={handlePlayerChange}
        value={props.player}
        variant="standard"
        fullWidth
        sx={{ minWidth: 150 }}
      >
        {rows.map((player) => (
          <MenuItem key={player.id} value={player.id}>
            {player.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default PlayerSelect;
