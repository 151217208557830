import { gql } from "@apollo/client";

export const GET_TEAMS = gql`
  query teams {
    teams {
      id
      name
      manager {
        id
        user {
          id
          username
        }
      }
    }
  }
`;

export const GET_TEAMS_BY_SEASON_ID = gql`
  query teamsBySeasonId($id: Long) {
    teamsBySeasonId(id: $id) {
      id
      name
      manager {
        id
        user {
          id
          username
        }
      }
    }
  }
`;

export const GET_TEAMS_BY_MANAGER_ID = gql`
  query teamsByManagerId($id: Long) {
    teamsByManagerId(id: $id) {
      id
      name
      manager {
        id
        user {
          id
          username
        }
      }
    }
  }
`;

export const GET_TEAMS_NOT_BY_SEASON_ID = gql`
  query teamsNotBySeasonId($id: Long) {
    teamsNotBySeasonId(id: $id) {
      id
      name
      manager {
        id
        user {
          id
          username
        }
      }
    }
  }
`;

export const ADD_TEAM = gql`
  mutation createTeam($managerId: Long!, $name: String!) {
    createTeam(managerId: $managerId, name: $name) {
      id
      name
      manager {
        id
        user {
          id
          username
        }
      }
    }
  }
`;

export const UPDATE_TEAM = gql`
  mutation updateTeam($id: Long!, $managerId: Long!, $name: String!) {
    updateTeam(id: $id, managerId: $managerId, name: $name) {
      id
      name
      manager {
        id
        user {
          id
          username
        }
      }
    }
  }
`;

export const DELETE_TEAM = gql`
  mutation deleteTeam($id: Long!) {
    deleteTeam(id: $id) {
      id
      name
      manager {
        id
        user {
          id
          username
        }
      }
    }
  }
`;

export const GET_TEAM = gql`
  query team($id: Long) {
    team(id: $id) {
      id
      name
      manager {
        id
        user {
          id
          username
        }
      }
    }
  }
`;

export const TEAMS_WITH_PERMISSION = gql`
  query teamsWithPermission {
    teamsWithPermission {
      id
    }
  }
`;
