import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";
import { UPDATE_SEASON } from "../../GraphQL/Season";

const EditSeasonDialog = (props) => {
  const [season, setSeason] = useState("");

  useEffect(() => {
    setSeason(props.season);
  }, [props]);

  const [updateSeason, { loading, error }] = useMutation(UPDATE_SEASON, {
    variables: {
      id: season.id,
      name: season.name,
      startDate: season.startDate,
      endDate: season.endDate,
    },
  });

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>Season</DialogTitle>
        <DialogContent>
          <TextField
            data-testid="name"
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.season.name}
            onChange={(e) => {
              setSeason({ ...season, name: e.target.value });
            }}
            InputProps={{ inputProps: { maxLength: 20 } }}
          />
          <TextField
            data-testid="startDate"
            autoFocus
            margin="dense"
            id="startDate"
            label="Start Date"
            type="date"
            fullWidth
            variant="standard"
            defaultValue={props.season.startDate}
            onChange={(e) => {
              setSeason({ ...season, startDate: e.target.value });
            }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            data-testid="endDate"
            autoFocus
            margin="dense"
            id="endDate"
            label="End Date"
            type="date"
            fullWidth
            variant="standard"
            defaultValue={props.season.endDate}
            onChange={(e) => {
              setSeason({ ...season, endDate: e.target.value });
            }}
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="saveEditSeasonDialogButton"
            onClick={() => {
              updateSeason();
              props.setOpen(false);
            }}
          >
            Update
          </Button>
          <Button
            data-testid="closeEditSeasonDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditSeasonDialog;
