import React, { useState } from "react";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditUserDialog from "./EditUserDialog";

const EditUserButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Button
        data-testid="editUserButton"
        sx={{ marginLeft: 1 }}
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        <EditIcon />
      </Button>

      <EditUserDialog
        open={open}
        setOpen={setOpen}
        user={props.user}
      ></EditUserDialog>
    </div>
  );
};

export default EditUserButton;
