import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditTeamButton from "./EditTeamButton";
import DeleteTeamButton from "./DeleteTeamButton";

const TeamTable = (props) => {
  const navigate = useNavigate();
  const handleRowClick = (row) => {
    navigate(`/team/${row.id}`);
  };

  const rows = props.teams.map(({ id, name, manager }) => ({
    id: id,
    name: name,
    manager: manager.user.username,
  }));

  const teamsWithPermission = props.teamsWithPermission.map((team) => team.id);

  return (
    <div>
      <TableContainer data-testid="teamTable" component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {teamsWithPermission.length > 0 && <TableCell></TableCell>}
              <TableCell>Name</TableCell>
              <TableCell>Manager</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                data-testid={"teamTableRow" + row.id}
                hover
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => handleRowClick(row)}
              >
                {teamsWithPermission.includes(row.id) && (
                  <TableCell
                    onClick={(e) => e.stopPropagation()}
                    style={{ width: 160 }}
                  >
                    <Box display="flex">
                      <EditTeamButton
                        sx={{ marginLeft: "auto" }}
                        variant="contained"
                        managers={props.managers}
                        team={row}
                      />
                      <DeleteTeamButton
                        sx={{ marginLeft: "auto" }}
                        variant="contained"
                        team={row}
                      />
                    </Box>
                  </TableCell>
                )}
                {!teamsWithPermission.includes(row.id) &&
                  teamsWithPermission.length > 0 && <TableCell></TableCell>}
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.manager}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TeamTable;
