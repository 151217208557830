import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { UPDATE_COMPETITION } from "../../GraphQL/Competitions";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";

const EditCompetitionDialog = (props) => {
  const [competition, setCompetition] = useState("");

  useEffect(() => {
    setCompetition(props.competition);
  }, [props]);

  const [updateCompetition, { loading, error }] = useMutation(
    UPDATE_COMPETITION,
    {
      variables: {
        id: props.competition.id,
        name: competition.name,
        description: competition.description,
        url: competition.url,
      },
    }
  );

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>Competition</DialogTitle>
        <DialogContent>
          <TextField
            data-testid="name"
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.competition.name}
            onChange={(e) => {
              setCompetition({ ...competition, name: e.target.value });
            }}
            inputProps={{ maxLength: 20 }}
          />
          <TextField
            data-testid="description"
            autoFocus
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.competition.description}
            onChange={(e) =>
              setCompetition({ ...competition, description: e.target.value })
            }
            inputProps={{ maxLength: 20 }}
          />
          <TextField
            data-testid="url"
            autoFocus
            margin="dense"
            id="url"
            label="URL"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.competition.url}
            onChange={(e) =>
              setCompetition({ ...competition, url: e.target.value })
            }
            inputProps={{ maxLength: 20 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="saveEditCompetitionDialogButton"
            onClick={() => {
              updateCompetition();
              props.setOpen(false);
            }}
          >
            Update
          </Button>
          <Button
            data-testid="closeEditCompetitionDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditCompetitionDialog;
