import React, { useState } from "react";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditTransferRequestDialog from "./EditTransferRequestDialog";

const EditTransferRequestButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Button
        data-testid="editTransferRequestButton"
        sx={{ marginLeft: 1 }}
        variant="contained"
        onClick={() => setOpen(true)}
      >
        <EditIcon />
      </Button>

      <EditTransferRequestDialog
        open={open}
        setOpen={setOpen}
        transferRequest={props.transferRequest}
        players={props.players}
        teams={props.teams}
      ></EditTransferRequestDialog>
    </div>
  );
};

export default EditTransferRequestButton;
