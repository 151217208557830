import { useState } from "react";
import { Button, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddTeamToSeasonDialog from "./AddTeamToSeasonDialog";

const AddTeamToSeasonButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Button
        data-testid="addTeamToSeasonButton"
        sx={{ marginLeft: 1 }}
        variant="contained"
        onClick={() => setOpen(true)}
      >
        <AddIcon />
        Add Team
      </Button>

      <AddTeamToSeasonDialog
        open={open}
        setOpen={setOpen}
        seasonId={props.seasonId}
        teams={props.teams}
      ></AddTeamToSeasonDialog>
    </div>
  );
};

export default AddTeamToSeasonButton;
