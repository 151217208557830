import * as React from "react";
import { useQuery } from "@apollo/client";
import { GET_CURRENT_USER } from "../../GraphQL/Users";
import { GET_CURRENT_PLAYER } from "../../GraphQL/Players";
import { GET_CURRENT_MANAGER } from "../../GraphQL/Managers";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import Profile from "../../Components/Profile/Profile";
import PlayerButton from "../../Components/Player/PlayerButton";
import ManagerButton from "../../Components/Manager/ManagerButton";
import RegisterPlayerButton from "../../Components/Player/RegisterPlayerButton";
import RegisterManagerButton from "../../Components/Manager/RegisterManagerButton";

const ProfilePage = () => {
  const user = useQuery(GET_CURRENT_USER);
  const player = useQuery(GET_CURRENT_PLAYER, { pollInterval: 5000 });
  const manager = useQuery(GET_CURRENT_MANAGER, { pollInterval: 5000 });

  if (user.loading || player.loading || manager.loading)
    return <LoadingDialog />;

  return (
    <div className="container">
      <h3>Profile</h3>
      <Profile user={user.data.currentUser}></Profile>

      <div className="row">
        <div className="col">
          {player.data.currentPlayer && (
            <PlayerButton
              playerId={player.data.currentPlayer.id}
            ></PlayerButton>
          )}
          {!player.data.currentPlayer && (
            <RegisterPlayerButton></RegisterPlayerButton>
          )}
        </div>
        <div className="col">
          {manager.data.currentManager && (
            <ManagerButton
              managerId={manager.data.currentManager.id}
            ></ManagerButton>
          )}
          {!manager.data.currentManager && (
            <RegisterManagerButton></RegisterManagerButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
