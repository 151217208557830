import React, { useState } from "react";
import { Button } from "@mui/material";
import RegisterManagerDialog from "./RegisterManagerDialog";

const RegisterManagerButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Button
        data-testid="registerManagerButton"
        sx={{ marginLeft: 1 }}
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        Register as Manager
      </Button>

      <RegisterManagerDialog
        open={open}
        setOpen={setOpen}
      ></RegisterManagerDialog>
    </div>
  );
};

export default RegisterManagerButton;
