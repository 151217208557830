import React, { useState, createContext } from "react";

export const LoginContext = createContext();

export const LoginProvider = (props) => {
  const [loggedIn, setLoggedIn] = useState(null);

  if(loggedIn == null) {
    setLoggedIn(false);
    localStorage.removeItem("token");
  }

  return (
    <LoginContext.Provider value={[loggedIn, setLoggedIn]}>
      {props.children}
    </LoginContext.Provider>
  );
};
