import React, { useState } from "react";
import { Button } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import EditPlayerDialog from "./EditPlayerDialog";

const EditPlayerButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Button
        data-testid="editPlayerButton"
        sx={{ marginLeft: 1 }}
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        <EditIcon />
      </Button>

      <EditPlayerDialog
        open={open}
        setOpen={setOpen}
        player={props.player}
      ></EditPlayerDialog>
    </div>
  );
};

export default EditPlayerButton;
