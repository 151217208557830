import { gql } from "@apollo/client";

export const GET_APPEARANCE = gql`
  query appearance($id: Long) {
    appearance(id: $id) {
      id
      player {
        id
        name
      }
      team {
        id
        name
      }
      match {
        id
        homeTeam {
          id
          name
        }
        awayTeam {
          id
          name
        }
      }
      goals
      assists
      passes
      interceptions
      tackles
      yellowCards
      redCards
    }
  }
`;

export const GET_APPEARANCES_BY_MATCH_ID = gql`
  query appearancesByMatchId($id: Long) {
    appearancesByMatchId(id: $id) {
      id
      player {
        name
      }
      team {
        id
        name
      }
      match {
        id
      }
      goals
      assists
      passes
      interceptions
      tackles
      yellowCards
      redCards
    }
  }
`;

export const GET_APPEARANCES_BY_SEASON_ID = gql`
  query appearancesBySeasonId($id: Long) {
    appearancesBySeasonId(id: $id) {
      id
      player {
        name
      }
      team {
        id
        name
      }
      match {
        id
        season {
          id
        }
        homeTeam {
          id
          name
        }
        awayTeam {
          id
          name
        }
      }
      goals
      assists
      passes
      interceptions
      tackles
      yellowCards
      redCards
    }
  }
`;

export const GET_APPEARANCES_BY_PLAYER_ID = gql`
  query appearancesByPlayerId($id: Long) {
    appearancesByPlayerId(id: $id) {
      id
      player {
        name
      }
      team {
        id
        name
      }
      goals
      assists
      passes
      interceptions
      tackles
      yellowCards
      redCards
    }
  }
`;

export const ADD_APPEARANCE = gql`
  mutation createAppearance(
    $matchId: Long!
    $playerId: Long!
    $teamId: Long!
    $goals: Int!
    $assists: Int!
    $passes: Int!
    $interceptions: Int!
    $tackles: Int!
    $yellowCards: Int!
    $redCards: Int!
  ) {
    createAppearance(
      matchId: $matchId
      playerId: $playerId
      teamId: $teamId
      goals: $goals
      assists: $assists
      passes: $passes
      interceptions: $interceptions
      tackles: $tackles
      yellowCards: $yellowCards
      redCards: $redCards
    ) {
      id
      player {
        id
        name
      }
      team {
        id
        name
      }
      match {
        id
      }
      goals
      assists
      passes
      interceptions
      tackles
      yellowCards
      redCards
    }
  }
`;

export const UPDATE_APPEARANCE = gql`
  mutation updateAppearance(
    $id: Long!
    $goals: Int!
    $assists: Int!
    $passes: Int!
    $interceptions: Int!
    $tackles: Int!
    $yellowCards: Int!
    $redCards: Int!
  ) {
    updateAppearance(
      id: $id
      goals: $goals
      assists: $assists
      passes: $passes
      interceptions: $interceptions
      tackles: $tackles
      yellowCards: $yellowCards
      redCards: $redCards
    ) {
      id
      player {
        id
        name
      }
      team {
        id
        name
      }
      match {
        id
      }
      goals
      assists
      passes
      interceptions
      tackles
      yellowCards
      redCards
    }
  }
`;

export const DELETE_APPEARANCE = gql`
  mutation deleteAppearance($id: Long!) {
    deleteAppearance(id: $id) {
      id
      player {
        id
        name
      }
      team {
        id
        name
      }
      match {
        id
      }
      goals
      assists
      passes
      interceptions
      tackles
      yellowCards
      redCards
    }
  }
`;

export const APPEARANCES_WITH_PERMISSION = gql`
  query appearancesWithPermission {
    appearancesWithPermission {
      id
    }
  }
`;
