import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";
import { DELETE_MATCH } from "../../GraphQL/Matches";

const DeleteMatchDialog = (props) => {
  const [match, setMatch] = useState("");

  useEffect(() => {
    setMatch(props.match);
  }, [props]);

  const [deleteMatch, { loading, error }] = useMutation(DELETE_MATCH, {
    variables: {
      id: props.match.id,
    },
  });

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>Match</DialogTitle>
        <DialogContent>
          <TextField
            data-testid="homeTeam"
            autoFocus
            margin="dense"
            id="homeTeam"
            label="Home Team"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.match.homeTeam.name}
            disabled
          />
          <TextField
            data-testid="awayTeam"
            autoFocus
            margin="dense"
            id="awayTeam"
            label="Away Team"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.match.awayTeam.name}
            disabled
          />
          <TextField
            data-testid="startDate"
            autoFocus
            margin="dense"
            id="startDate"
            label="Start Date"
            type="date"
            fullWidth
            variant="standard"
            defaultValue={props.match.startDate}
            disabled
          />
          <FormControlLabel
            label="Has Been Played?"
            control={
              <Checkbox
                data-testid="hasBeenPlayed"
                autoFocus
                margin="dense"
                id="hasBeenPlayed"
                label="Has Been Played?"
                variant="standard"
                disabled
                checked={props.match.hasBeenPlayed}
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="saveDeleteMatchDialogButton"
            onClick={() => {
              deleteMatch();
              props.setOpen(false);
            }}
          >
            Delete
          </Button>
          <Button
            data-testid="closeDeleteMatchDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteMatchDialog;
