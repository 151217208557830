import { useState, useContext } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { LOGIN } from "../../GraphQL/Login";
import { LoginContext } from "./LoginContext";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";

const LoginDialog = (props) => {
  const [credentials, setCredentials] = useState("");
  const [loggedIn, setLoggedIn] = useContext(LoginContext);

  const [login, { loading, error }] = useMutation(LOGIN, {
    variables: {
      username: credentials.username,
      password: credentials.password,
    },
    onCompleted: (response) => {
      localStorage.setItem("token", response.login.token);
      setLoggedIn(true);
    },
  });

  if (loading) return <LoadingDialog />;
  if (error)
    return <ErrorDialog data-testid="errorDialog" message={error?.message} />;

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>Login</DialogTitle>
        <DialogContent>
          <TextField
            data-testid="username"
            autoFocus
            margin="dense"
            id="username"
            label="Username"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setCredentials({ ...credentials, username: e.target.value });
            }}
            InputProps={{ inputProps: { maxLength: 20 } }}
          />
          <TextField
            data-testid="password"
            autoFocus
            margin="dense"
            id="password"
            label="Password"
            type="password"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setCredentials({ ...credentials, password: e.target.value });
            }}
            InputProps={{ inputProps: { maxLength: 20 } }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="login"
            onClick={() => {
              login();
              props.setOpen(false);
            }}
          >
            Login
          </Button>
          <Button onClick={() => props.setOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LoginDialog;
