import { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { ADD_TRANSFER_REQUEST } from "../../GraphQL/TransferRequests.js";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";
import PlayerSelect from "../Player/PlayerSelect";
import TeamSelect from "../Team/TeamSelect";

const NewTransferRequestDialog = (props) => {
  const [transferRequest, setTransferRequest] = useState({
    playerAccepted: false,
    teamAccepted: false,
  });
  const [player, setPlayer] = useState("");
  const [team, setTeam] = useState("");

  const [addTransferRequest, { loading, error }] = useMutation(
    ADD_TRANSFER_REQUEST,
    {
      variables: {
        playerId: player,
        teamId: team,
        playerAccepted: transferRequest.playerAccepted,
        teamAccepted: true,
        requestDate: transferRequest.requestDate,
      },
    }
  );

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>Transfer Request</DialogTitle>
        <DialogContent>
          <PlayerSelect
            player={player}
            setPlayer={setPlayer}
            players={props.players}
          />
          <TeamSelect teams={props.teams} team={team} setTeam={setTeam} />
          <FormControlLabel
            label="Player Accepted"
            control={
              <Checkbox
                data-testid="playerAccepted"
                autoFocus
                margin="dense"
                id="playerAccepted"
                label="Player Accepted"
                variant="standard"
                disabled
                onChange={(e) => {
                  setTransferRequest({
                    ...transferRequest,
                    playerAccepted: e.target.checked,
                  });
                }}
              />
            }
          />
          <FormControlLabel
            label="Team Accepted"
            control={
              <Checkbox
                data-testid="teamAccepted"
                autoFocus
                margin="dense"
                id="teamAccepted"
                label="Team Accepted"
                variant="standard"
                checked
                disabled
              />
            }
          />
          <TextField
            data-testid="requestDate"
            autoFocus
            margin="dense"
            id="requestDate"
            label="Request Date"
            type="date"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setTransferRequest({
                ...transferRequest,
                requestDate: e.target.value,
              });
            }}
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="saveNewTransferRequestDialogButton"
            onClick={() => {
              addTransferRequest();
              props.setOpen(false);
            }}
          >
            Create
          </Button>
          <Button
            data-testid="closeNewTransferRequestDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewTransferRequestDialog;
