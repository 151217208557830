import { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";
import { ADD_SEASON } from "../../GraphQL/Season";

const NewSeasonDialog = (props) => {
  const [season, setSeason] = useState("");

  const [addSeason, { loading, error }] = useMutation(ADD_SEASON, {
    variables: {
      name: season.name,
      startDate: season.startDate,
      endDate: season.endDate,
      competitionId: props.competitionId,
    },
  });

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>Season</DialogTitle>
        <DialogContent>
          <TextField
            data-testid="name"
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setSeason({ ...season, name: e.target.value });
            }}
            InputProps={{ inputProps: { maxLength: 20 } }}
          />
          <TextField
            data-testid="startDate"
            autoFocus
            margin="dense"
            id="startDate"
            label="Start Date"
            type="date"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setSeason({ ...season, startDate: e.target.value });
            }}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            data-testid="endDate"
            autoFocus
            margin="dense"
            id="endDate"
            label="End Date"
            type="date"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setSeason({ ...season, endDate: e.target.value });
            }}
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="saveNewSeasonDialogButton"
            onClick={() => {
              addSeason();
              props.setOpen(false);
            }}
          >
            Create
          </Button>
          <Button
            data-testid="closeNewSeasonDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewSeasonDialog;
