import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { DELETE_COMPETITION } from "../../GraphQL/Competitions";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";

const DeleteCompetitionDialog = (props) => {
  const [competition, setCompetition] = useState("");

  useEffect(() => {
    setCompetition(props.competition);
  }, [props]);

  const [deleteCompetition, { loading, error }] = useMutation(
    DELETE_COMPETITION,
    {
      variables: {
        id: props.competition.id,
      },
    }
  );

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>Competition</DialogTitle>
        <DialogContent>
          <TextField
            data-testid="name"
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.competition.name}
            disabled
          />
          <TextField
            data-testid="description"
            autoFocus
            margin="dense"
            id="description"
            label="Description"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.competition.description}
            disabled
          />
          <TextField
            data-testid="url"
            autoFocus
            margin="dense"
            id="url"
            label="URL"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.competition.url}
            disabled
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="saveDeleteCompetitionDialogButton"
            onClick={() => {
              deleteCompetition();
              props.setOpen(false);
            }}
          >
            Delete
          </Button>
          <Button
            data-testid="closeDeleteCompetitionDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteCompetitionDialog;
