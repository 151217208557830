import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import LoadingDialog from "../Loading/LoadingDialog";
import ErrorDialog from "../Error/ErrorDialog.js";
import { UPDATE_USER } from "../../GraphQL/Users";

const EditUserDialog = (props) => {
  const [user, setUser] = useState("");

  useEffect(() => {
    setUser(props.user);
  }, [props]);

  const [updateUser, { loading, error }] = useMutation(UPDATE_USER, {
    variables: {
      id: props.user.id,
      discriminator: user.discriminator,
      discordId: user.discordId,
      username: user.username,
      email: user.email,
      icon: user.icon,
      originId: user.originId,
    },
  });

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>User</DialogTitle>
        <DialogContent>
          <TextField
            data-testid="discriminator"
            autoFocus
            margin="dense"
            id="discriminator"
            label="Discriminator"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.user.discriminator}
            onChange={(e) => {
              var value = parseInt(e.target.value);

              if (value > 9999) value = 9999;
              if (value < 0) value = 0;
              e.target.value = value;
              setUser({ ...user, discriminator: value });
            }}
            InputProps={{ inputProps: { min: 0, max: 9999 } }}
          />
          <TextField
            data-testid="discordId"
            autoFocus
            margin="dense"
            id="discordId"
            label="Discord ID"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.user.discordId}
            onChange={(e) => {
              setUser({ ...user, discordId: e.target.value });
            }}
            InputProps={{ inputProps: { maxLength: 50 } }}
          />
          <TextField
            data-testid="username"
            autoFocus
            margin="dense"
            id="username"
            label="Username"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.user.username}
            onChange={(e) => {
              setUser({ ...user, username: e.target.value });
            }}
            InputProps={{ inputProps: { maxLength: 50 } }}
          />
          <TextField
            data-testid="email"
            autoFocus
            margin="dense"
            id="email"
            label="Email"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.user.email}
            onChange={(e) => {
              setUser({ ...user, email: e.target.value });
            }}
            InputProps={{ inputProps: { maxLength: 50 } }}
          />
          <TextField
            data-testid="icon"
            autoFocus
            margin="dense"
            id="icon"
            label="Icon"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.user.icon}
            onChange={(e) => {
              setUser({ ...user, icon: e.target.value });
            }}
            InputProps={{ inputProps: { maxLength: 50 } }}
          />
          <TextField
            data-testid="originId"
            autoFocus
            margin="dense"
            id="originId"
            label="Origin ID"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.user.originId}
            onChange={(e) => {
              setUser({ ...user, originId: e.target.value });
            }}
            InputProps={{ inputProps: { maxLength: 50 } }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="saveEditUserDialogButton"
            onClick={() => {
              updateUser();
              props.setOpen(false);
            }}
          >
            Update
          </Button>
          <Button
            data-testid="closeEditUserDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditUserDialog;
