import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import LoadingDialog from "../Loading/LoadingDialog";
import ErrorDialog from "../Error/ErrorDialog.js";
import { REMOVE_TEAM_FROM_SEASON } from "../../GraphQL/Season";
import TeamSelect from "./TeamSelect";

const RemoveTeamFromSeasonDialog = (props) => {
  const [team, setTeam] = useState(null);

  const [removeTeamFromSeason, { loading, error }] = useMutation(
    REMOVE_TEAM_FROM_SEASON,
    {
      variables: {
        seasonId: props.seasonId,
        teamId: team,
      },
    }
  );

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>Team</DialogTitle>
        <DialogContent>
          <TeamSelect
            seasonId={props.seasonId}
            team={team}
            setTeam={setTeam}
            teams={props.teams}
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="saveRemoveTeamFromSeasonDialogButton"
            onClick={() => {
              removeTeamFromSeason();
              props.setOpen(false);
            }}
          >
            Remove
          </Button>
          <Button
            data-testid="closeRemoveTeamFromSeasonDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RemoveTeamFromSeasonDialog;
