import { Dialog, DialogContent, DialogTitle } from "@mui/material";

const LoadingDialog = () => {
  return (
    <div>
      <Dialog open={true}>
        <DialogTitle>Loading</DialogTitle>
        <DialogContent>Loading... Please wait</DialogContent>
      </Dialog>
    </div>
  );
};

export default LoadingDialog;
