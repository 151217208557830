import { Button, Box } from "@mui/material";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { useNavigate } from "react-router-dom";

const ProfileButton = (props) => {
  const navigate = useNavigate();
  const handleRowClick = () => {
    navigate(`/profile`);
  };

  return (
    <div>
      <Box display="flex">
        <Button
          data-testid="profileButton"
          sx={{ marginLeft: "auto" }}
          variant="contained"
          onClick={() => handleRowClick()}
        >
          <AccountBoxIcon></AccountBoxIcon>
        </Button>
      </Box>
    </div>
  );
};

export default ProfileButton;
