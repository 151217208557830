import { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";
import { ADD_MATCH } from "../../GraphQL/Matches.js";
import TeamSelect from "../Team/TeamSelect.js";

const NewMatchDialog = (props) => {
  const [match, setMatch] = useState({ hasBeenPlayed: false });
  const [homeTeam, setHomeTeam] = useState("");
  const [awayTeam, setAwayTeam] = useState("");

  const [addMatch, { loading, error }] = useMutation(ADD_MATCH, {
    variables: {
      seasonId: props.seasonId,
      homeTeamId: homeTeam,
      awayTeamId: awayTeam,
      startDate: match.startDate,
      hasBeenPlayed: match.hasBeenPlayed,
    },
  });

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>Match</DialogTitle>
        <DialogContent>
          <TeamSelect
            teams={props.teams}
            team={homeTeam}
            setTeam={setHomeTeam}
          />
          <TeamSelect
            teams={props.teams}
            team={awayTeam}
            setTeam={setAwayTeam}
          />
          <TextField
            data-testid="startDate"
            autoFocus
            margin="dense"
            id="startDate"
            label="Start Date"
            type="date"
            fullWidth
            variant="standard"
            onChange={(e) => {
              setMatch({ ...match, startDate: e.target.value });
            }}
            InputLabelProps={{ shrink: true }}
          />
          <FormControlLabel
            label="Has Been Played?"
            control={
              <Checkbox
                data-testid="hasBeenPlayed"
                autoFocus
                margin="dense"
                id="hasBeenPlayed"
                label="Has Been Played?"
                variant="standard"
                onChange={(e) => {
                  setMatch({ ...match, hasBeenPlayed: e.target.checked });
                }}
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="saveNewMatchDialogButton"
            onClick={() => {
              addMatch();
              props.setOpen(false);
            }}
          >
            Create
          </Button>
          <Button
            data-testid="closeNewMatchDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NewMatchDialog;
