import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditPlayerButton from "./EditPlayerButton";
import DeletePlayerButton from "./DeletePlayerButton";

const PlayersTable = (props) => {
  const navigate = useNavigate();
  const handleRowClick = (row) => {
    navigate(`/player/${row.id}`);
  };

  const rows = props.players.map(({ id, name, originId, user, team }) => ({
    id: id,
    name: name,
    originId: originId,
    user: user,
    team: team,
  }));

  const playersWithPermission = props.playersWithPermission.map(
    (player) => player.id
  );

  return (
    <div>
      <TableContainer data-testid="playersTable" component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {playersWithPermission.length > 0 && <TableCell></TableCell>}
              <TableCell>Name</TableCell>
              <TableCell>User</TableCell>
              <TableCell>Team</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                data-testid={"playerTableRow" + row.id}
                hover
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => handleRowClick(row)}
              >
                {playersWithPermission.includes(row.id) && (
                  <TableCell
                    onClick={(e) => e.stopPropagation()}
                    style={{ width: 160 }}
                  >
                    <Box display="flex">
                      <EditPlayerButton
                        sx={{ marginLeft: "auto" }}
                        variant="contained"
                        player={row}
                      />
                      <DeletePlayerButton
                        sx={{ marginLeft: 1 }}
                        variant="contained"
                        player={row}
                      />
                    </Box>
                  </TableCell>
                )}
                {!playersWithPermission.includes(row.id) &&
                  playersWithPermission.length > 0 && <TableCell></TableCell>}
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.user.username}</TableCell>
                <TableCell>{row.team.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default PlayersTable;
