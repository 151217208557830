import { gql } from "@apollo/client";

export const GET_SEASONS_BY_COMPETITION_ID = gql`
  query seasonsByCompetitionId($id: Long) {
    seasonsByCompetitionId(id: $id) {
      id
      name
      startDate
      endDate
      competition {
        id
        name
      }
    }
  }
`;

export const ADD_TEAM_TO_SEASON = gql`
  mutation addTeamToSeason($teamId: Long!, $seasonId: Long!) {
    addTeamToSeason(teamId: $teamId, seasonId: $seasonId) {
      id
      name
    }
  }
`;

export const REMOVE_TEAM_FROM_SEASON = gql`
  mutation removeTeamFromSeason($teamId: Long!, $seasonId: Long!) {
    removeTeamFromSeason(teamId: $teamId, seasonId: $seasonId) {
      id
      name
    }
  }
`;

export const ADD_SEASON = gql`
  mutation createSeason(
    $competitionId: Long!
    $name: String!
    $startDate: Date!
    $endDate: Date!
  ) {
    createSeason(
      competitionId: $competitionId
      name: $name
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      name
      startDate
      endDate
    }
  }
`;

export const DELETE_SEASON = gql`
  mutation deleteSeason($id: Long!) {
    deleteSeason(id: $id) {
      id
      name
      startDate
      endDate
    }
  }
`;

export const UPDATE_SEASON = gql`
  mutation updateSeason(
    $id: Long!
    $name: String!
    $startDate: Date!
    $endDate: Date!
  ) {
    updateSeason(
      id: $id
      name: $name
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      name
      startDate
      endDate
    }
  }
`;

export const GET_SEASON = gql`
  query season($id: Long) {
    season(id: $id) {
      id
      name
      startDate
      endDate
      competition {
        id
        name
      }
    }
  }
`;

export const GET_SEASONS_CONTAINING_TEAM_ID = gql`
  query seasonsContainingTeamId($id: Long) {
    seasonsContainingTeamId(id: $id) {
      id
      name
      startDate
      endDate
      competition {
        id
        name
      }
    }
  }
`;

export const SEASONS_WITH_PERMISSION = gql`
  query seasonsWithPermission {
    seasonsWithPermission {
      id
    }
  }
`;
