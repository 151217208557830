import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Checkbox,
} from "@mui/material";
import EditTransferRequestButton from "./EditTransferRequestButton";
import DeleteTransferRequestButton from "./DeleteTransferRequestButton";

const TransferRequestsTable = (props) => {
  const rows = props.transferRequests.map(
    ({ id, player, team, playerAccepted, teamAccepted, requestDate }) => ({
      id: id,
      player: player,
      team: team,
      playerAccepted: playerAccepted,
      teamAccepted: teamAccepted,
      requestDate: requestDate,
    })
  );

  const transferRequestsWithPermission =
    props.transferRequestsWithPermission.map(
      (transferRequest) => transferRequest.id
    );

  return (
    <div>
      <TableContainer data-testid="transferRequestsTable" component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {transferRequestsWithPermission.length > 0 && (
                <TableCell></TableCell>
              )}
              <TableCell>Player Name</TableCell>
              <TableCell>Team Name</TableCell>
              <TableCell>Player Accepted</TableCell>
              <TableCell>Team Accepted</TableCell>
              <TableCell>Request Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                data-testid={"transferRequestsTableRow" + row.id}
                hover
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {transferRequestsWithPermission.includes(row.id) && (
                  <TableCell
                    onClick={(e) => e.stopPropagation()}
                    style={{ width: 160 }}
                  >
                    <Box display="flex">
                      <EditTransferRequestButton
                        sx={{ marginLeft: "auto" }}
                        variant="contained"
                        transferRequest={row}
                        players={props.players}
                        teams={props.teams}
                      />
                      <DeleteTransferRequestButton
                        sx={{ marginLeft: 1 }}
                        variant="contained"
                        transferRequest={row}
                      />
                    </Box>
                  </TableCell>
                )}
                {!transferRequestsWithPermission.includes(row.id) &&
                  transferRequestsWithPermission.length > 0 && (
                    <TableCell></TableCell>
                  )}
                <TableCell>{row.player.name}</TableCell>
                <TableCell>{row.team.name}</TableCell>
                <TableCell>
                  <Checkbox checked={row.playerAccepted} disabled />
                </TableCell>
                <TableCell>
                  <Checkbox checked={row.teamAccepted} disabled />
                </TableCell>
                <TableCell>{row.requestDate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TransferRequestsTable;
