import "./Styles/style.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { LoginProvider } from "./Components/Login/LoginContext.js";
import Navigation from "./Navigation/Navigation.js";
import HomePage from "./Pages/Home/HomePage.js";
import AdminPage from "./Pages/Admin/AdminPage.js";
import TransferRequestPage from "./Pages/TransferRequest/TransferRequestPage.js";
import ProfilePage from "./Pages/Profile/ProfilePage";
import CompetitionsPage from "./Pages/Competition/CompetitionsPage";
import TeamsPage from "./Pages/Team/TeamsPage";
import UsersPage from "./Pages/User/UsersPage";
import CompetitionPage from "./Pages/Competition/CompetitionPage";
import TeamPage from "./Pages/Team/TeamPage";
import ManagerPage from "./Pages/Manager/ManagerPage";
import UserPage from "./Pages/User/UserPage";
import SeasonPage from "./Pages/Season/SeasonPage";
import PlayerPage from "./Pages/Player/PlayerPage";
import MatchPage from "./Pages/Match/MatchPage";
import AppearancePage from "./Pages/Appearance/AppearancePage";
import { ThemeProvider, createTheme } from "@mui/material";
import { orange, green, yellow, grey } from "@mui/material/colors";

function App() {
  const theme = createTheme({
    palette: {
      mode: "dark",
      primary: orange,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <LoginProvider>
        <Router>
          <div className="App">
            <Navigation></Navigation>
            <Routes>
              <Route exact path="/" element={<HomePage />} />
              <Route exact path="/admin" element={<AdminPage />} />
              <Route exact path="/profile" element={<ProfilePage />} />
              <Route
                exact
                path="/competitions"
                element={<CompetitionsPage />}
              />
              <Route exact path="/teams" element={<TeamsPage />} />
              <Route path="/users" element={<UsersPage />} />
              <Route
                path="/competition/:competitionId"
                element={<CompetitionPage />}
              />
              <Route
                path="/transferRequests"
                element={<TransferRequestPage />}
              />
              <Route path="/team/:teamId" element={<TeamPage />} />
              <Route path="/manager/:managerId" element={<ManagerPage />} />
              <Route path="/user/:userId" element={<UserPage />} />
              <Route path="/season/:seasonId" element={<SeasonPage />} />
              <Route path="/player/:playerId" element={<PlayerPage />} />
              <Route path="/match/:matchId" element={<MatchPage />} />
              <Route
                path="/appearance/:appearanceId"
                element={<AppearancePage />}
              />
            </Routes>
          </div>
        </Router>
      </LoginProvider>
    </ThemeProvider>
  );
}

export default App;
