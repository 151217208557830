import React from "react";
import { Select, MenuItem, InputLabel } from "@mui/material";

const TeamSelect = (props) => {
  const handleTeamChange = (event) => {
    props.setTeam(event.target.value);
  };

  const rows = props.teams.map(({ id, name }) => ({
    id: id,
    name: name,
  }));

  return (
    <div>
      <InputLabel id="team-select-label">Team</InputLabel>
      <Select
        data-testid="team-select"
        labelId="team-select-label"
        onChange={handleTeamChange}
        value={props.team}
        variant="standard"
        fullWidth
        sx={{ minWidth: 150 }}
      >
        {rows.map((team) => (
          <MenuItem key={team.id} value={team.id}>
            {team.name}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default TeamSelect;
