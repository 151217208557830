import React, { useContext } from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { GET_SEASON, SEASONS_WITH_PERMISSION } from "../../GraphQL/Season.js";
import {
  GET_MATCHES_BY_SEASON_ID,
  MATCHES_WITH_PERMISSION,
} from "../../GraphQL/Matches.js";
import {
  GET_TEAMS_BY_SEASON_ID,
  TEAMS_WITH_PERMISSION,
} from "../../GraphQL/Teams.js";
import { GET_MANAGERS } from "../../GraphQL/Managers";
import { GET_TEAMS_NOT_BY_SEASON_ID } from "../../GraphQL/Teams.js";
import { GET_APPEARANCES_BY_SEASON_ID } from "../../GraphQL/Appearances.js";
import SeasonOverview from "../../Components/Season/SeasonOverview.js";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import { LoginContext } from "../../Components/Login/LoginContext";
import MatchTable from "../../Components/Match/MatchTable.js";
import NewMatchButton from "../../Components/Match/NewMatchButton.js";
import CompetitionButton from "../../Components/Competition/CompetitionButton.js";
import TeamTable from "../../Components/Team/TeamTable.js";
import AddTeamToSeasonButton from "../../Components/Team/AddTeamToSeasonButton.js";
import RemoveTeamFromSeasonButton from "../../Components/Team/RemoveTeamFromSeasonButton.js";
import LeagueTable from "../../Components/LeagueTable/LeagueTable.js";
import "../../Styles/style.css";
import Box from "@mui/material/Box";

const SeasonPage = () => {
  const { seasonId } = useParams();

  console.log("here1");

  const season = useQuery(GET_SEASON, {
    variables: {
      id: seasonId,
    },
    pollInterval: 5000,
  });

  const matches = useQuery(GET_MATCHES_BY_SEASON_ID, {
    variables: {
      id: seasonId,
    },
    pollInterval: 5000,
  });

  const teamsInSeason = useQuery(GET_TEAMS_BY_SEASON_ID, {
    variables: {
      id: seasonId,
    },
    pollInterval: 5000,
  });

  const teamsNotInSeason = useQuery(GET_TEAMS_NOT_BY_SEASON_ID, {
    variables: {
      id: seasonId,
    },
    pollInterval: 5000,
  });

  const appearancesInSeason = useQuery(GET_APPEARANCES_BY_SEASON_ID, {
    variables: {
      id: seasonId,
    },
    pollInterval: 5000,
  });

  let seasonsWithPermission = useQuery(SEASONS_WITH_PERMISSION, {
    pollInterval: 5000,
  });

  const matchesWithPermission = useQuery(MATCHES_WITH_PERMISSION, {
    pollInterval: 5000,
  });

  const teamsWithPermission = useQuery(TEAMS_WITH_PERMISSION, {
    pollInterval: 5000,
  });

  const managers = useQuery(GET_MANAGERS, {
    pollInterval: 5000,
  });

  const [loggedIn] = useContext(LoginContext);

  if (
    season.loading ||
    matches.loading ||
    teamsInSeason.loading ||
    teamsNotInSeason.loading ||
    appearancesInSeason.loading ||
    seasonsWithPermission.loading ||
    matchesWithPermission.loading ||
    teamsWithPermission.loading ||
    managers.loading
  )
    return <LoadingDialog />;

  seasonsWithPermission = seasonsWithPermission.data.seasonsWithPermission.map(
    (season) => season.id
  );

  console.log("here");

  return (
    <div className="container">
      <h3>Season</h3>
      <SeasonOverview season={season.data.season}></SeasonOverview>
      <CompetitionButton
        competition={season.data.season.competition}
      ></CompetitionButton>

      <div className="row mt-5">
        <div className="col-5">
          <h3>Teams</h3>
          <TeamTable
            managers={managers.data.managers}
            teams={teamsInSeason.data.teamsBySeasonId}
            teamsWithPermission={teamsWithPermission.data.teamsWithPermission}
          ></TeamTable>

          <Box sx={{ display: "inline-flex" }}>
            {loggedIn && seasonsWithPermission.includes(parseInt(seasonId)) && (
              <AddTeamToSeasonButton
                sx={{ marginLeft: "auto" }}
                variant="contained"
                seasonId={seasonId}
                teams={teamsNotInSeason.data.teamsNotBySeasonId}
              />
            )}
            {loggedIn && seasonsWithPermission.includes(parseInt(seasonId)) && (
              <RemoveTeamFromSeasonButton
                sx={{ marginLeft: "auto" }}
                variant="contained"
                seasonId={seasonId}
                teams={teamsInSeason.data.teamsBySeasonId}
              />
            )}
          </Box>
        </div>
        <div className="col-1"></div>
        <div className="col-6">
          <h3>League Table</h3>
          <LeagueTable
            appearances={appearancesInSeason.data.appearancesBySeasonId}
          ></LeagueTable>
        </div>

        <div className="row mt-5">
          <h3>Fixtures</h3>
          {loggedIn && seasonsWithPermission.includes(parseInt(seasonId)) && (
            <NewMatchButton
              seasonId={seasonId}
              teams={teamsInSeason.data.teamsBySeasonId}
            ></NewMatchButton>
          )}
          <MatchTable
            matches={matches.data.matchesBySeasonId}
            matchesWithPermission={
              matchesWithPermission.data.matchesWithPermission
            }
          ></MatchTable>
        </div>
      </div>
    </div>
  );
};

export default SeasonPage;
