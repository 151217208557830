import * as React from "react";
import "../../Styles/style.css";

const AdminPage = () => {
  return (
    <div className="container">
      <h3>Admin</h3>Admin
    </div>
  );
};

export default AdminPage;
