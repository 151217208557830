import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";
import { UPDATE_MATCH } from "../../GraphQL/Matches";

const EditMatchDialog = (props) => {
  const [match, setMatch] = useState("");

  useEffect(() => {
    setMatch(props.match);
  }, [props]);

  const [updateMatch, { loading, error }] = useMutation(UPDATE_MATCH, {
    variables: {
      id: props.match.id,
      startDate: match.startDate,
      hasBeenPlayed: match.hasBeenPlayed,
    },
  });

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>Match</DialogTitle>
        <DialogContent>
          <TextField
            data-testid="startDate"
            autoFocus
            margin="dense"
            id="startDate"
            label="Start Date"
            type="date"
            fullWidth
            variant="standard"
            defaultValue={props.match.startDate}
            onChange={(e) => {
              setMatch({ ...match, startDate: e.target.value });
            }}
            InputLabelProps={{ shrink: true }}
          />
          <FormControlLabel
            label="Has Been Played?"
            control={
              <Checkbox
                data-testid="hasBeenPlayed"
                autoFocus
                margin="dense"
                id="hasBeenPlayed"
                label="Has Been Played?"
                variant="standard"
                defaultChecked={props.match.hasBeenPlayed}
                onChange={(e) => {
                  setMatch({ ...match, hasBeenPlayed: e.target.checked });
                }}
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="saveEditMatchDialogButton"
            onClick={() => {
              updateMatch();
              props.setOpen(false);
            }}
          >
            Update
          </Button>
          <Button
            data-testid="closeEditMatchDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default EditMatchDialog;
