import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import LoginButton from "../Components/Login/LoginButton.js";
import LogoutButton from "../Components/Login/LogoutButton.js";
import RegisterButton from "../Components/Login/RegisterButton.js";
import ProfileButton from "../Components/Profile/ProfileButton.js";

const NavigationBar = (props) => {
  return (
    <div data-testid="navigationBar">
      <List style={{ display: "flex", flexDirection: "row", padding: 0 }}>
        <ListItemButton key={1} divider component={Link} to="/">
          <ListItemIcon>
            <ListItemText sx={{ color: "white" }}>Community</ListItemText>
          </ListItemIcon>
        </ListItemButton>
        <ListItemButton
          data-testid="competitionLink"
          key={2}
          divider
          component={Link}
          to="/competitions"
        >
          <ListItemIcon>
            <ListItemText sx={{ color: "white" }}>Competitions</ListItemText>
          </ListItemIcon>
        </ListItemButton>
        <ListItemButton key={3} divider component={Link} to="/teams">
          <ListItemIcon>
            <ListItemText sx={{ color: "white" }}>Teams</ListItemText>
          </ListItemIcon>
        </ListItemButton>
        <ListItemButton key={4} divider component={Link} to="/transferRequests">
          <ListItemIcon>
            <ListItemText sx={{ color: "white" }}>Transfer Market</ListItemText>
          </ListItemIcon>
        </ListItemButton>
        {props.showActions && (
          <ListItemButton key={5} divider component={Link} to="/users">
            <ListItemIcon>
              <ListItemText sx={{ color: "white" }}>Users</ListItemText>
            </ListItemIcon>
          </ListItemButton>
        )}

        {!props.showActions && (
          <ListItemButton key={6} divider>
            <ListItemIcon>
              <LoginButton></LoginButton>
            </ListItemIcon>
          </ListItemButton>
        )}
        {!props.showActions && (
          <ListItemButton key={7} divider>
            <ListItemIcon>
              <RegisterButton></RegisterButton>
            </ListItemIcon>
          </ListItemButton>
        )}
        {props.showActions && (
          <ListItemButton key={8} divider>
            <ListItemIcon>
              <ProfileButton></ProfileButton>
            </ListItemIcon>
          </ListItemButton>
        )}
        {props.showActions && (
          <ListItemButton key={9} divider>
            <ListItemIcon>
              <LogoutButton></LogoutButton>
            </ListItemIcon>
          </ListItemButton>
        )}
      </List>
    </div>
  );
};

export default NavigationBar;
