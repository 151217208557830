import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import EditUserButton from "./EditUserButton";
import DeleteUserButton from "./DeleteUserButton";

const UserTable = (props) => {
  const navigate = useNavigate();
  const handleRowClick = (row) => {
    navigate(`/user/${row.id}`);
  };

  const rows = props.users.map(
    ({ id, discriminator, discordId, username, email, icon, originId }) => ({
      id: id,
      discriminator: discriminator,
      discordId: discordId,
      username: username,
      email: email,
      icon: icon,
      originId: originId,
    })
  );

  return (
    <div>
      <TableContainer data-testid="userTable" component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {props.showActions && <TableCell></TableCell>}
              <TableCell>Discriminator</TableCell>
              <TableCell>Discord ID</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Icon</TableCell>
              <TableCell>Origin ID</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                data-testid={"userTableRow" + row.id}
                hover
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                onClick={() => handleRowClick(row)}
              >
                {props.showActions && (
                  <TableCell
                    onClick={(e) => e.stopPropagation()}
                    style={{ width: 160 }}
                  >
                    <Box display="flex">
                      <EditUserButton
                        sx={{ marginLeft: "auto" }}
                        variant="contained"
                        user={row}
                      />
                      <DeleteUserButton
                        sx={{ marginLeft: "auto" }}
                        variant="contained"
                        user={row}
                      />
                    </Box>
                  </TableCell>
                )}
                <TableCell>{row.discriminator}</TableCell>
                <TableCell>{row.discordId}</TableCell>
                <TableCell>{row.username}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>
                  {" "}
                  <img
                    src={row.icon}
                    alt="row.username.profile.icon"
                    style={{ width: "50px" }}
                  />
                </TableCell>
                <TableCell>{row.originId}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UserTable;
