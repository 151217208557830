import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import LoadingDialog from "../Loading/LoadingDialog";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";
import { DELETE_TEAM } from "../../GraphQL/Teams";

const DeleteTeamDialog = (props) => {
  const [team, setTeam] = useState("");

  useEffect(() => {
    setTeam(props.team);
  }, [props]);

  const [deleteTeam, { loading, error }] = useMutation(DELETE_TEAM, {
    variables: {
      id: props.team.id,
    },
  });

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>Team</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="manager"
            label="Manager"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.team.manager}
            disabled
          />
          <TextField
            data-testid="name"
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.team.name}
            disabled
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="saveDeleteTeamDialogButton"
            onClick={() => {
              deleteTeam();
              props.setOpen(false);
            }}
          >
            Delete
          </Button>
          <Button
            data-testid="closeDeleteTeamDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteTeamDialog;
