import { useState } from "react";
import { Button, Box } from "@mui/material";
import NewCompetitionDialog from "./NewCompetitionDialog";
import AddIcon from "@mui/icons-material/Add";

const NewCompetitionButton = () => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Box display="flex">
        <Button
          data-testid="newCompetitionButton"
          sx={{ marginLeft: 1 }}
          variant="contained"
          onClick={() => setOpen(true)}
        >
          <AddIcon />
          Create Competition
        </Button>
      </Box>

      <NewCompetitionDialog
        open={open}
        setOpen={setOpen}
      ></NewCompetitionDialog>
    </div>
  );
};

export default NewCompetitionButton;
