import React, { useContext } from "react";
import { useParams } from "react-router";
import { useQuery } from "@apollo/client";
import { GET_APPEARANCE } from "../../GraphQL/Appearances.js";
import { LoginContext } from "../../Components/Login/LoginContext";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import { Grid } from "@mui/material";
import PlayerButton from "../../Components/Player/PlayerButton.js";
import TeamButton from "../../Components/Team/TeamButton.js";
import MatchButton from "../../Components/Match/MatchButton.js";
import "../../Styles/style.css";

const AppearancePage = () => {
  const { appearanceId } = useParams();

  const appearance = useQuery(GET_APPEARANCE, {
    variables: {
      id: appearanceId,
    },
    pollInterval: 5000,
  });

  const [loggedIn] = useContext(LoginContext);

  if (appearance.loading) return <LoadingDialog />;

  return (
    <div className="container">
      <h3>Appearance</h3>
      <Grid container spacing={2}>
        <Grid item xs={3}></Grid>
        <Grid item xs={2}>
          <PlayerButton
            playerId={appearance.data.appearance.player.id}
          ></PlayerButton>
        </Grid>
        <Grid item xs={2}>
          <MatchButton
            matchId={appearance.data.appearance.match.id}
          ></MatchButton>
        </Grid>
        <Grid item xs={2}>
          <TeamButton team={appearance.data.appearance.team}></TeamButton>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <ul>
            <li>Player Name: {appearance.data.appearance.player.name}</li>
            <li>Team Name: {appearance.data.appearance.team.name}</li>
            <li>Home Team: {appearance.data.appearance.match.homeTeam.name}</li>
            <li>Away Team: {appearance.data.appearance.match.awayTeam.name}</li>
            <li>Goals: {appearance.data.appearance.goals}</li>
            <li>Assists: {appearance.data.appearance.assists}</li>
            <li>Passes: {appearance.data.appearance.passes}</li>
            <li>Interceptions: {appearance.data.appearance.interceptions}</li>
            <li>Tackles: {appearance.data.appearance.tackles}</li>
            <li>Yellow Cards: {appearance.data.appearance.yellowCards}</li>
            <li>Red Cards: {appearance.data.appearance.redCards}</li>
          </ul>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>
    </div>
  );
};

export default AppearancePage;
