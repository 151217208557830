import { Button } from "@mui/material";
import { Link } from "react-router-dom";

const CompetitionButton = (props) => {
  return (
    <div>
      <Button
        component={Link}
        to={"/competition/" + props.competition.id}
        sx={{ marginLeft: 1 }}
        variant="contained"
      >
        {props.competition.name}
      </Button>
    </div>
  );
};

export default CompetitionButton;
