import { useState, useEffect } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import LoadingDialog from "../../Components/Loading/LoadingDialog.js";
import ErrorDialog from "../../Components/Error/ErrorDialog.js";
import { DELETE_APPEARANCE } from "../../GraphQL/Appearances";

const DeleteAppearanceDialog = (props) => {
  const [appearance, setAppearance] = useState("");

  useEffect(() => {
    setAppearance(props.appearance);
  }, [props]);

  const [deleteAppearance, { loading, error }] = useMutation(
    DELETE_APPEARANCE,
    {
      variables: {
        id: props.appearance.id,
      },
    }
  );

  if (loading) return <LoadingDialog />;
  if (error) return <ErrorDialog message={error?.message} />;

  return (
    <div>
      <Dialog open={props.open}>
        <DialogTitle>Appearance</DialogTitle>
        <DialogContent>
          <TextField
            data-testid="name"
            autoFocus
            margin="dense"
            id="player"
            label="Name"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={props.appearance.player.name}
            disabled
          />
          <TextField
            data-testid="goals"
            autoFocus
            margin="dense"
            id="goals"
            label="Goals"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={props.appearance.goals}
            disabled
          />
          <TextField
            data-testid="assists"
            autoFocus
            margin="dense"
            id="assists"
            label="Assists"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={props.appearance.assists}
            disabled
          />
          <TextField
            data-testid="passes"
            autoFocus
            margin="dense"
            id="passes"
            label="Passes"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={props.appearance.passes}
            disabled
          />
          <TextField
            data-testid="interceptions"
            autoFocus
            margin="dense"
            id="interceptions"
            label="Interceptions"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={props.appearance.interceptions}
            disabled
          />
          <TextField
            data-testid="tackles"
            autoFocus
            margin="dense"
            id="tackles"
            label="Tackles"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={props.appearance.tackles}
            disabled
          />
          <TextField
            data-testid="yellowCards"
            autoFocus
            margin="dense"
            id="yellowCards"
            label="Yellow Cards"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={props.appearance.yellowCards}
            disabled
          />
          <TextField
            data-testid="redCards"
            autoFocus
            margin="dense"
            id="redCards"
            label="Red Cards"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={props.appearance.redCards}
            disabled
          />
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="saveDeleteAppearanceDialogButton"
            onClick={() => {
              deleteAppearance();
              props.setOpen(false);
            }}
          >
            Delete
          </Button>
          <Button
            data-testid="closeDeleteAppearanceDialogButton"
            onClick={() => props.setOpen(false)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteAppearanceDialog;
