import React, { useState } from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteCompetitionDialog from "./DeleteCompetitionDialog";

const DeleteCompetitionButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Button
        data-testid="deleteCompetitionButton"
        sx={{ marginLeft: 1 }}
        variant="contained"
        onClick={() => {
          setOpen(true);
        }}
      >
        <DeleteIcon />
      </Button>

      <DeleteCompetitionDialog
        open={open}
        setOpen={setOpen}
        competition={props.competition}
      ></DeleteCompetitionDialog>
    </div>
  );
};

export default DeleteCompetitionButton;
