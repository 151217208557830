import React from "react";
import { Grid, Paper, Typography } from "@mui/material";

const ManagerOverview = (props) => {
  return (
    <div>
      <Paper
        sx={{
          p: 2,
          margin: "auto",
          maxWidth: 500,
          flexGrow: 1,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs>
                <Typography gutterBottom variant="subtitle1" component="div">
                  {props.manager.id}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {props.manager.user.username}
                </Typography>
              </Grid>
              <Grid item>---</Grid>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" component="div">
                {props.manager.id}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ManagerOverview;
